@import '../../../../scss/_theme-bootstrap';

.gnav-email-signup {
  &__trigger {
    font-size: 15px;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
    .icon--email {
      font-size: 22px;
      margin-#{$rdirection}: 3px;
    }
  }
  &__content {
    width: 380px;
    position: absolute;
    text-align: center;
    z-index: 10;
    display: none;
    .active & {
      display: flex;
    }
    .disabled-overlay & {
      display: none;
    }
    &-wrapper {
      margin-top: 20px;
      border: 1px solid $color-black;
      transition: none;
      background: $color-white;
    }
    &-arrow {
      margin-#{$ldirection}: 70px;
      position: absolute;
      &-up.icon {
        font-size: 20px;
        background: $color-white;
        transform: rotate(-90deg);
        width: 16px;
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;
      }
    }
  }
  &__form {
    text-align: $ldirection;
    margin: 0 13%;
    p {
      margin: 0;
      line-height: 1;
    }
    &.success-gnav-email-signup {
      .gnav-email-signup {
        &__message-success {
          display: block;
        }
        &__title,
        &__subtitle,
        &__copy,
        &__fields,
        &__legal,
        &__submit,
        &__terms-conditions__more,
        &__qr-image__select,
        &__terms-conditions {
          display: none;
        }
      }
    }
  }
  &__message {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.01em;
    &-error {
      color: $color-red;
      li {
        list-style: none;
      }
    }
    &-success {
      color: $color-black;
      display: none;
    }
  }
  &__success {
    padding: 70px 0;
    text-align: center;
    &-header {
      @include font--subheading;
      text-transform: none;
      margin-bottom: 14px;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 1.5;
      @if $cr18 == false {
        color: $color-black;
      }
    }
    &-offer {
      line-height: 1.2;
      font-size: 16px;
      letter-spacing: 1px;
      &__code {
        @include font--subheading;
        color: $color-black;
        font-family: $font--futura-medium;
        text-transform: uppercase;
      }
    }
    &-terms {
      margin-top: 12px;
      margin-bottom: 20px;
      a {
        text-decoration: underline;
        text-transform: unset;
        color: $color-mid-gray;
      }
    }
    &-cta {
      margin-bottom: 25px;
      .button--medium {
        min-width: 172px;
      }
    }
    &-reminder {
      font-size: 14px;
    }
  }
  &__terms-conditions,
  &__terms-conditions__more {
    padding-bottom: 12px;
    color: $color-black;
    font-size: 12px;
    text-transform: none;
    p {
      font-size: inherit;
    }
    a {
      text-decoration: underline;
    }
  }
  &__terms-conditions {
    display: none;
    font-size: 12px;
    color: $color-dark-gray;
    position: relative;
    &-checkbox {
      color: $color-black;
      font-size: 12px;
      text-transform: none;
      p {
        margin-top: 0;
        font-size: 9px;
        font-family: $font--futura-medium;
        text-align: $ldirection;
        color: $color-black;
        @include breakpoint($landscape-up) {
          font-size: 12px;
          max-width: 300px;
          line-height: 1.4;
        }
        & > a {
          text-decoration: underline;
          font-family: $font--futura-medium;
          font-size: inherit;
        }
      }
    }
  }
  &__qr-image__select {
    text-align: center;
    padding-bottom: 12px;
  }
  &__legal {
    padding-bottom: 12px;
    position: relative;
    &:hover > .gnav-email-signup__legal-data-overlay {
      display: block;
    }
    &-title {
      line-height: 1;
      font-size: 15px;
    }
    &-data-overlay {
      display: none;
      position: absolute;
      padding: 10px 15px;
      border-radius: 5px;
      background-color: $color-white;
      border: 1px solid $color-dark-gray;
      width: 100%;
      text-align: justify;
      bottom: 100%;
      font-size: 15px;
    }
  }
  &__close {
    color: $color-black;
    cursor: pointer;
    font-weight: 900;
    float: $rdirection;
    text-align: $rdirection;
    padding: 15px;
    width: 100%;
    text-decoration: none;
    &:hover,
    &:active,
    &focus {
      text-decoration: none;
    }
  }
  &__title {
    @include font--heading;
    text-align: center;
    font-size: 25px;
    letter-spacing: 1;
    margin: 0 0 6px;
    line-height: 32px;
    padding-bottom: 10px;
    &-text-large,
    &-text-medium,
    &-text-small {
      text-align: $ldirection;
      font-family: $font--futura-demi;
    }
  }
  &__subtitle {
    @include font--text;
    font-size: 30px;
    color: $color-black;
    text-align: center;
    margin: 0;
    font-style: italic;
    padding-bottom: 10px;
    &-text-large,
    &-text-medium,
    &-text-small {
      text-align: $ldirection;
      text-transform: uppercase;
      font-style: normal;
      letter-spacing: 0;
      line-height: 1.2;
    }
  }
  &__title,
  &__subtitle {
    &-text-large {
      font-size: 27px;
      @include breakpoint($landscape-up) {
        font-size: 30px;
      }
    }
    &-text-medium {
      font-size: 25px;
    }
    &-text-small {
      font-size: 18px;
      @include breakpoint($landscape-up) {
        font-size: 16px;
      }
    }
  }
  &__copy {
    text-align: center;
    font-size: 16px;
    color: $color-dark-gray;
    padding-bottom: 12px;
  }
  &__fields {
    @include pie-clearfix;
    width: 100%;
    margin: 0 auto;
    input[type='email'],
    input[type='tel'] {
      height: 30px;
      margin-bottom: 12px;
      width: 100%;
      line-height: 1.2;
    }
  }
  &__submit {
    width: 100%;
    line-height: 1;
    margin: 0 0 15px;
    display: block;
    &.button {
      height: 30px;
      line-height: 1;
      border: none;
    }
    &-align-left {
      width: auto;
      float: $ldirection;
    }
    &-align-center {
      margin: auto auto 15px;
      width: auto;
    }
    &-align-right {
      float: $rdirection;
      width: auto;
    }
  }
}
